import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CredenciadoModel } from "../models/credenciado.model";

@Injectable()
export class CredenciadoService {
  credenciado: any;

  idcred = 0;

  credenciadoConsulta: CredenciadoModel;
  idModuloCredenciadoConsulta: number = 1;

  constructor(private http: HttpClient) {}

  addProfissionalManutencao(dados: any): Observable<any> {
    return this.http.post("credenciado/addProfissional", {
      credenciado: dados,
    });
  }

  addObservacao(dados: any): Observable<any> {
    return this.http.post("credenciado/salvarObservacao", {
      credenciado: dados,
    });
  }

  setCredenciadoProfissional(dados: any): Observable<any> {
    return this.http.post("credenciado/setCredenciadoProfissional", {
      credenciado: dados,
    });
  }

  setCredenciadoRelatorios(credenciado: any, idModulo: number) {
    this.credenciadoConsulta = credenciado;
    this.idModuloCredenciadoConsulta = idModulo;
  }

  getCredenciadoRelatorios() {
    return {credenciadoConsulta: this.credenciadoConsulta, idModuloCredenciadoConsulta: this.idModuloCredenciadoConsulta};
  }

  getCredenciados(filtros: any): Observable<any> {
    return this.http.get("credenciado", {
      params: filtros,
    });
  }

  getCredenciadosInformativo(filtros: any): Observable<any> {
    return this.http.get("credenciado/informativo", {
      params: filtros,
    });
  }

  getCredenciadoProdutos(filtros: any): Observable<any> {
    return this.http.get("credenciado/produto", {
      params: filtros,
    });
  }

  getCredenciadoInfo(credenciadoid: any): Observable<any> {
    return this.http.get("credenciado/info", {
      params: credenciadoid,
    });
  }

  getCredenciadoInfoTransacoes(credenciadoid: any): Observable<any> {
    return this.http.get("credenciado/infoTrans", {
      params: credenciadoid,
    });
  }

  getCredenciadoInfoTransacoesReject(credenciadoid: any): Observable<any> {
    return this.http.get("credenciado/infoTransReject", {
      params: credenciadoid,
    });
  }

  getCredenciadoRamo(ramo: any): Observable<any> {
    return this.http.get("credenciado/ramo", {
      params: ramo,
    });
  }

  getCredenciadoSegmento(segmento: any): Observable<any> {
    return this.http.get("credenciado/segmento", {
      params: segmento,
    });
  }

  getCredenciadoBandeira(): Observable<any> {
    return this.http.get("credenciado/bandeira");  }


  saveCredenciado(dados: any): Observable<any> {
    return this.http.post("credenciado/salvar", {
      credenciado: dados,
    });
  }

  WSNetCardPP_ConsCredenciadoPP(dados: any): Observable<any> {
    return this.http.post("credenciado/WSNetCardPP_ConsCredenciadoPP", dados);
  }

  getWSProtheusPP(dados: any): Observable<any> {
    return this.http.post("credenciado/WSProtheusPP_ConsultaCredenciadoSQL", {
      codtn: dados,
    });
  }

  getObservacoes(filtro): Observable<any> {
    return this.http.get("credenciado/metodos/getobservacoes", {
      params: filtro,
    });
  }

  getRegistros(filtro): Observable<any> {
    return this.http.get("credenciado/registros", {
      params: filtro,
    });
  }

  getNovoLogin(filtros: any): Observable<any> {
    return this.http.get("credenciado/metodos/getnovologin", {
      params: filtros,
    });
  }

  getCredenciadosGood(filtros: any): Observable<any> {
    return this.http.get("credenciado/good", {
      params: filtros,
    });
  }

  getAllProdutos(): Observable<any> {
    return this.http.get("credenciado/produtosAll");
  }
}
